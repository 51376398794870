var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "header-marcar_consulta bg-gradient-primary"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('h1', [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "BookOpenIcon",
      "size": "30"
    }
  }), _vm._v(" Turmas ")], 1)]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.fetchClassrooms.apply(null, arguments);
      }
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "placeholder": "Buscar Turma"
    },
    model: {
      value: _vm.filtro.name,
      callback: function callback($$v) {
        _vm.$set(_vm.filtro, "name", $$v);
      },
      expression: "filtro.name"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary button-right"
    },
    on: {
      "click": _vm.nextPage
    }
  }, [_vm._v(" Adicionar Turma")])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-pagination', {
    staticClass: "ml-auto",
    attrs: {
      "total-rows": _vm.fetchedClassrooms.totalItems,
      "per-page": _vm.perPage
    },
    on: {
      "input": _vm.onChangePage
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    ref: "refUserListTable",
    staticClass: "position-relative",
    attrs: {
      "items": _vm.fetchedClassrooms.content,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "sort-by": _vm.sortBy,
      "show-empty": "",
      "empty-text": "No matching records found",
      "sort-desc": _vm.isSortDirDesc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(active)",
      fn: function fn(data) {
        return [_c('b-badge', {
          staticClass: "text-capitalize",
          attrs: {
            "pill": "",
            "variant": "light-".concat(_vm.resolveUserStatusVariant(data.item.active))
          }
        }, [_vm._v(" " + _vm._s(_vm.resolveUserStatusText(data.item.active)) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "no-caret": "",
            "right": _vm.$store.state.appConfig.isRTL
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "align-middle text-body",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'editarTurma',
              params: {
                turmaId: data.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v("Editar Turma")])], 1)], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-pagination', {
    staticClass: "ml-auto",
    attrs: {
      "total-rows": _vm.fetchedClassrooms.totalItems,
      "per-page": _vm.fetchedClassrooms.perPage
    },
    model: {
      value: _vm.fetchedClassrooms.currentPage,
      callback: function callback($$v) {
        _vm.$set(_vm.fetchedClassrooms, "currentPage", $$v);
      },
      expression: "fetchedClassrooms.currentPage"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary">
          <b-row align-v="center">
            <b-col lg="3">
              <h1>
                <feather-icon icon="BookOpenIcon" size="30" class="mr-50" />
                Turmas
              </h1>
            </b-col>
            <b-col lg="6">
              <b-form @submit.prevent="fetchClassrooms">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="filtro.name"
                    placeholder="Buscar Turma"
                  />
                </b-input-group>
              </b-form>
            </b-col>
            <b-col lg="3">
              <b-button
                variant="primary button-right"
                @click="nextPage"
              >
                Adicionar Turma</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <div class="d-flex">
      <b-pagination
        @input="onChangePage"
        class="ml-auto"
        v-model="currentPage"
        :total-rows="fetchedClassrooms.totalItems"
        :per-page="perPage"
      ></b-pagination>
    </div>
    <b-card no-body>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchedClassrooms.content"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Status -->
        <template #cell(active)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.active)}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusText(data.item.active) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'editarTurma', params: { turmaId: data.item.id} }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar Turma</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>
      </b-table>
    </b-card>
    <div class="d-flex">
      <b-pagination
        class="ml-auto"
        v-model="fetchedClassrooms.currentPage"
        :total-rows="fetchedClassrooms.totalItems"
        :per-page="fetchedClassrooms.perPage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import classroomService from '@/services/classroomService'
import { hideSpinner, showSpinner } from '@/services/spinService'
import {
BAvatar, BBadge, BButton, BCard, BCol, BDropdown,
BDropdownItem, BForm, BFormGroup, BFormInput, BFormTextarea, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BLink,
BMedia, BPagination, BRow, BTable
} from 'bootstrap-vue'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BButton,
    BForm,
    flatPickr,
  },
  data: () => ({
    configFlatPickr: { dateFormat: 'd/m/Y', locale: Portuguese },
    filtro: {
      name: '',
    },
    perPage: 10,
    totalUsers: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    searchQuery: '',
    sortBy: 'id',
    isSortDirDesc: true,
    roleFilter: null,
    planFilter: null,
    statusFilter: null,
    fetchedClassrooms: [],
    tableColumns: [
      { key: 'turma', label: 'Turma', sortable: true },
      { key: 'turno', label: 'Turno', sortable: true },
      { key: 'ano', label: 'Ano', sortable: true },
      { key: 'actions', label: 'Acesso', sortable: false },
    ],
  }),
  mounted() {
    this.fetchClassrooms()
  },
  methods: {
    nextPage() {
      this.$router.push(`/escolas/${this.$route.params.id}/turmas/adicionar`)
    },
    onChangePage() {
      this.fetchClassrooms()
    },
    prepareRequest(obj) {
      return {
        ...obj,
        page: this.currentPage,
        itemsPerPage: this.perPage,
        schoolId: this.$route.params.id,
      }
    },
    async fetchClassrooms() {
      showSpinner()
      this.fetchedClassrooms = await classroomService.getAll(
        this.prepareRequest(this.filtro),
      )
      hideSpinner()
    },
    resolveUserStatusText: status => (status ? 'Ativo' : 'Inativo'),

    resolveUserStatusVariant: status => (status ? 'success' : 'danger'),
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
